import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  // {
  //  sousId: 1,
  //  tokenName: 'DAI',
  //  stakingTokenName: QuoteToken.TACO,
  //  stakingTokenAddress: '0x4EB033a22ee1D173EFcd808d5ea6152792814ecd',
  //  contractAddress: {
  //   137: '0x462C91e1E5a596Ef74bABF46F68061A1836f020d'
  //  },
  //  poolCategory: PoolCategory.COMMUNITY,
  //  projectLink: 'https://pancakeswap.finance/',
  //  harvest: true,
  //  tokenPerBlock: '0.0092',
  //  sortOrder: 1,
  //  isFinished: true,
  //  tokenDecimals: 18,
  // },
  /*
  {
    sousId: 2,
    tokenName: 'WBTC',
    stakingTokenName: QuoteToken['TACO-USDC'],
    stakingTokenAddress: '0xD592bfB50ad10088A428F1a4e74213090fC4A6dC',
    contractAddress: {
      137: '0x563373d51Eb8e9F9074216763a39F3aD79597edd'
    },
    poolCategory: PoolCategory.COMMUNITY,
    projectLink: 'https://platinumfinance.app/',
    harvest: true,
    tokenPerBlock: '0.00000034',
    sortOrder: 1,
    isFinished: false, 
    tokenDecimals: 8,
  }
  */

]

export default pools
