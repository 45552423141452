import contracts from './contracts'
import { VaultConfig, QuoteToken } from './types'

const vaults: VaultConfig[] = [
  {
    pid: 0,
    farmPid: 2,
    isTokenOnly: true,
    lpSymbol: 'TACO',
    decimal: 18,
    lpAddresses: {
      31337: '0x28583858a6018adfaac1e3864937c92ebfb7ac83',
      137: '0x28583858a6018adfaac1e3864937c92ebfb7ac83',
    },
    tokenSymbol: 'TACO',
    tokenAddresses: {
      31337: '0x4EB033a22ee1D173EFcd808d5ea6152792814ecd',
      137: '0x4EB033a22ee1D173EFcd808d5ea6152792814ecd',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
    earnSymbol: 'USDC',
    earnDecimal: 6,
    from: 'Taco',
    abiFile: 'vaultTacoStrategy', // dividends
    strategyAddresses: {
      31337: '0xf77537487E5cbc98531435FD77452bffE62f3Fe0',
      137: '0xf77537487E5cbc98531435FD77452bffE62f3Fe0',
    },
    compoundFrequency: 288,
    performanceFee: 0,
  },

  {
    pid: 1,
    farmPid: 0,
    isTokenOnly: false,
    lpSymbol: 'TACO-USDC',
    decimal: 18,
    lpAddresses: {
      31337: '0x28583858a6018adfaac1e3864937c92ebfb7ac83',
      137: '0x28583858a6018adfaac1e3864937c92ebfb7ac83',
    },
    tokenSymbol: 'TACO',
    tokenAddresses: {
      31337: '0x4EB033a22ee1D173EFcd808d5ea6152792814ecd',
      137: '0x4EB033a22ee1D173EFcd808d5ea6152792814ecd',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
    earnSymbol: 'TACO-USDC',
    earnDecimal: 18,
    from: 'Taco',
    abiFile: 'vaultStrategyMasterchef', // dividends
    strategyAddresses: {
      31337: '0xE2035AB9aa3576b35FaA2F7468006394dAeCcfAf',
      137: '0xE2035AB9aa3576b35FaA2F7468006394dAeCcfAf',
    },
    compoundFrequency: 288,
    performanceFee: 5,
  },

  {
    pid: 2,
    farmPid: 1,
    isTokenOnly: false,
    lpSymbol: 'WMATIC-TACO',
    decimal: 18,
    lpAddresses: {
      31337: '0x347fee1a891a678ee2cce532aba5f416d2fddba0',
      137: '0x347fee1a891a678ee2cce532aba5f416d2fddba0',
    },
    tokenSymbol: 'TACO',
    tokenAddresses: {
      31337: '0x4EB033a22ee1D173EFcd808d5ea6152792814ecd',
      137: '0x4EB033a22ee1D173EFcd808d5ea6152792814ecd',
    },
    quoteTokenSymbol: QuoteToken.MATIC,
    quoteTokenAdresses: contracts.wmatic,
    earnSymbol: 'TACO-WMATIC',
    earnDecimal: 18,
    from: 'Taco',
    abiFile: 'vaultStrategyMasterchef',
    strategyAddresses: {
      31337: '0x6b21fBb1469fA5D292254174e9DA43520fd82A8f',
      137: '0x6b21fBb1469fA5D292254174e9DA43520fd82A8f',
    },
    compoundFrequency: 288,
    performanceFee: 5,
  },

    /*
    {
    pid: 0,
    farmPid: 1,
    isTokenOnly: true,
    lpSymbol: 'SANDMAN',
    decimal: 18,
    lpAddresses: {
      137: '0x56de3448C9EE1ADCb05F26f5093b593D492695c8',
    },
    tokenSymbol: 'SANDMAN',
    tokenAddresses: {
      137: '0xf9b4dEFdDe04fe18F5ee6456607F8A2eC9fF6A75',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
    earnSymbol: 'USDC',
    earnDecimal: 6,
    from: 'Sandman',
    abiFile: 'vaultTacoStrategy',
    strategyAddresses: {
      137: '0x89BF8234E36ABcf91207C747B388E0cE3B30A4E3',
    },
    compoundFrequency: 288,
    performanceFee: 0,
  },
  {
    pid: 2,
    farmPid: 14,
    lpSymbol: 'SANDMAN-MATIC',
    decimal: 18,
    lpAddresses: {
      137: '0x3cd18d001a9152b3157A18C3cAe7b7F4b7e1977B',
    },
    tokenSymbol: 'SANDMAN',
    tokenAddresses: {
      137: '0xf9b4dEFdDe04fe18F5ee6456607F8A2eC9fF6A75',
    },
    quoteTokenSymbol: QuoteToken.MATIC,
    quoteTokenAdresses: contracts.wmatic,
    earnSymbol: 'SANDMAN-MATIC',
    earnDecimal: 18,
    from: 'Sandman',
    abiFile: 'vaultStrategyMasterchef',
    strategyAddresses: {
      137: '0xea72ad4Fe798daD0bCAed71c67d4b791856e29D3',
    },
    compoundFrequency: 288,
    performanceFee: 6.66,
  },
  {
    pid: 1,
    farmPid: 0,
    lpSymbol: 'SANDMAN-USDC',
    decimal: 18,
    lpAddresses: {
      137: '0x56de3448C9EE1ADCb05F26f5093b593D492695c8',
    },
    tokenSymbol: 'SANDMAN',
    tokenAddresses: {
      137: '0xf9b4dEFdDe04fe18F5ee6456607F8A2eC9fF6A75',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
    earnSymbol: 'SANDMAN-USDC',
    earnDecimal: 18,
    from: 'Sandman',
    abiFile: 'vaultStrategyMasterchef',
    strategyAddresses: {
      137: '0x29E57EEBa20D5aC9F425D1961C721CAE58C0E0d0',
    },
    compoundFrequency: 288,
    performanceFee: 6.66,
  },
  */
]

export default vaults
