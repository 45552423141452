import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 42,
    isTokenOnly: false,
    lpSymbol: 'TACO-USDC QLP',
    lpAddresses: {
      80001: '',
      137: '0x28583858a6018adfaac1e3864937c92ebfb7ac83',
      31337: '0x28583858a6018adfaac1e3864937c92ebfb7ac83',
    },
    tokenSymbol: 'TACO',
    tokenAddresses: {
      80001: '',
      137: '0x4EB033a22ee1D173EFcd808d5ea6152792814ecd',
      31337: '0x4EB033a22ee1D173EFcd808d5ea6152792814ecd',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 1,
    risk: 42,
    isTokenOnly: false,
    lpSymbol: 'TACO-WMATIC QLP',
    lpAddresses: {
      80001: '',
      137: '0x347fee1a891a678ee2cce532aba5f416d2fddba0',
      31337: '0x347fee1a891a678ee2cce532aba5f416d2fddba0',
    },
    tokenSymbol: 'WMATIC',
    tokenAddresses: {
      80001: '',
      137: '0x4EB033a22ee1D173EFcd808d5ea6152792814ecd',
      31337: '0x4EB033a22ee1D173EFcd808d5ea6152792814ecd',
    },
    quoteTokenSymbol: QuoteToken.TACO,
    quoteTokenAdresses: contracts.cake,
  },
  {
    pid: 2,
    risk: 42,
    isTokenOnly: true,
    lpSymbol: 'TACO',
    lpAddresses: {
      80001: '',
      137: '0x28583858a6018adfaac1e3864937c92ebfb7ac83',
      31337: '0x28583858a6018adfaac1e3864937c92ebfb7ac83', // TACO-USDC
    },
    tokenSymbol: 'TACO',
    tokenAddresses: {
      80001: '',
      137: '0x4EB033a22ee1D173EFcd808d5ea6152792814ecd',
      31337: '0x4EB033a22ee1D173EFcd808d5ea6152792814ecd',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 3,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'USDC',
    lpAddresses: {
      80001: '',
      137: '0x28583858a6018adfaac1e3864937c92ebfb7ac83',
      31337: '0x28583858a6018adfaac1e3864937c92ebfb7ac83',
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      80001: '0x2058a9d7613eee744279e3856ef0eada5fcbaa7e',
      137: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
      31337: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 4,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'USDT',
    lpAddresses: {
      80001: '',
      137: '0x2cf7252e74036d1da831d11089d326296e64a728',
      31337: '0x2cf7252e74036d1da831d11089d326296e64a728',
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      80001: '0x2058a9d7613eee744279e3856ef0eada5fcbaa7e',
      137: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
      31337: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 5,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'WMATIC',
    lpAddresses: {
      80001: '',
      137: '0x6e7a5fafcec6bb1e78bae2a1f0b612012bf14827', // MATIC-USDC LP
      31337: '0x6e7a5fafcec6bb1e78bae2a1f0b612012bf14827',
    },
    tokenSymbol: 'WMATIC',
    tokenAddresses: {
      80001: '0x9c3c9283d3e44854697cd22d3faa240cfb032889',
      137: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
      31337: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 6,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'WETH',
    lpAddresses: {
      80001: '',
      137: '0x853ee4b2a13f8a742d64c8f088be7ba2131f670d', // TOKEN-USDC LP
      31337: '0x853ee4b2a13f8a742d64c8f088be7ba2131f670d',
    },
    tokenSymbol: 'WETH',
    tokenAddresses: {
      80001: '',
      137: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
      31337: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 7,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'WBTC',
    lpAddresses: {
      80001: '',
      137: '0xf6a637525402643b0654a54bead2cb9a83c8b498', // TOKEN-USDC LP
      31337: '0xf6a637525402643b0654a54bead2cb9a83c8b498',
    },
    tokenSymbol: 'WBTC',
    tokenAddresses: {
      80001: '',
      137: '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
      31337: '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 8,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'QUICK',
    lpAddresses: {
      80001: '',
      137: '0x1f1e4c845183ef6d50e9609f16f6f9cae43bc9cb', // TOKEN-USDC LP
      31337: '0x1f1e4c845183ef6d50e9609f16f6f9cae43bc9cb',
    },
    tokenSymbol: 'QUICK',
    tokenAddresses: {
      80001: '',
      137: '0x831753dd7087cac61ab5644b308642cc1c33dc13',
      31337: '0x831753dd7087cac61ab5644b308642cc1c33dc13',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 9,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'LINK',
    lpAddresses: {
      80001: '',
      137: '0x70cee55c98f6da2685807611f115ea737d4a248e', // TOKEN-USDC LP
      31337: '0x70cee55c98f6da2685807611f115ea737d4a248e',
    },
    tokenSymbol: 'LINK',
    tokenAddresses: {
      80001: '',
      137: '0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39',
      31337: '0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 10,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'DAI',
    lpAddresses: {
      80001: '',
      137: '0xf04adbf75cdfc5ed26eea4bbbb991db002036bdd', // TOKEN-USDC LP
      31337: '0xf04adbf75cdfc5ed26eea4bbbb991db002036bdd',
    },
    tokenSymbol: 'DAI',
    tokenAddresses: {
      80001: '',
      137: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
      31337: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 11,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'NFTM',
    lpAddresses: {
      80001: '',
      137: '0xf7745CeD035F1BA7658E3b34Ae635181dE828c97', // TOKEN-USDC LP
      31337: '0xf7745CeD035F1BA7658E3b34Ae635181dE828c97',
    },
    tokenSymbol: 'NFTM',
    tokenAddresses: {
      80001: '',
      137: '0x5c59c3b8ab9c3bb5d8f8f84cf735944e87dfce2e',
      31337: '0x5c59c3b8ab9c3bb5d8f8f84cf735944e87dfce2e',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 12,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'ELK',
    lpAddresses: {
      80001: '',
      137: '0x7e95f968489382E695B2DA320ca005B8D617b459', // TOKEN-USDC LP
      31337: '0x7e95f968489382E695B2DA320ca005B8D617b459',
    },
    tokenSymbol: 'ELK',
    tokenAddresses: {
      80001: '',
      137: '0xe1c110e1b1b4a1ded0caf3e42bfbdbb7b5d7ce1c',
      31337: '0xe1c110e1b1b4a1ded0caf3e42bfbdbb7b5d7ce1c',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
    otherExchange: 'ElkSwap',
  },
  {
    pid: 13,
    risk: 42,
    isTokenOnly: false,
    lpSymbol: 'FISH-TACO PLP',
    lpAddresses: {
      80001: '',
      137: '0x6ad8f9eb292e817e4e70bade829845767b7cf08b', // TOKEN-USDC LP
      31337: '0x6ad8f9eb292e817e4e70bade829845767b7cf08b',
    },
    tokenSymbol: 'FISH',
    tokenAddresses: {
      80001: '',
      137: '0x4EB033a22ee1D173EFcd808d5ea6152792814ecd',
      31337: '0x4EB033a22ee1D173EFcd808d5ea6152792814ecd',
    },
    quoteTokenSymbol: QuoteToken.TACO,
    quoteTokenAdresses: contracts.cake,
    otherExchange: 'Polycat',
  },
  {
    pid: 14,
    risk: 42,
    isTokenOnly: false,
    lpSymbol: 'PAW-TACO PLP',
    lpAddresses: {
      80001: '',
      137: '0xcc324cbc6dc451a3843d0c8f82f56c338238e42b', // TOKEN-USDC LP
      31337: '0xcc324cbc6dc451a3843d0c8f82f56c338238e42b',
    },
    tokenSymbol: 'PAW',
    tokenAddresses: {
      80001: '',
      137: '0x4EB033a22ee1D173EFcd808d5ea6152792814ecd',
      31337: '0x4EB033a22ee1D173EFcd808d5ea6152792814ecd',
    },
    quoteTokenSymbol: QuoteToken.TACO,
    quoteTokenAdresses: contracts.cake,
    otherExchange: 'Polycat',
  },
  {
    pid: 15,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'FISH',
    lpAddresses: {
      80001: '',
      137: '0xbff681c59158ea5cf7d29e439cb701a9bb8b79f8', // TOKEN-USDC LP
      31337: '0xbff681c59158ea5cf7d29e439cb701a9bb8b79f8',
    },
    tokenSymbol: 'FISH',
    tokenAddresses: {
      80001: '',
      137: '0x3a3df212b7aa91aa0402b9035b098891d276572b',
      31337: '0x3a3df212b7aa91aa0402b9035b098891d276572b',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
    otherExchange: 'Polycat',
  },
  {
    pid: 16,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'PAW',
    lpAddresses: {
      80001: '',
      137: '0x4cd2b8b7e00ac8eb544c51c4b1f0bd39868a89df', // TOKEN-USDC LP
      31337: '0x4cd2b8b7e00ac8eb544c51c4b1f0bd39868a89df',
    },
    tokenSymbol: 'PAW',
    tokenAddresses: {
      80001: '',
      137: '0xbc5b59ea1b6f8da8258615ee38d40e999ec5d74f',
      31337: '0xbc5b59ea1b6f8da8258615ee38d40e999ec5d74f',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
    otherExchange: 'Polycat',
  },
  {
    pid: 17,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'ICE',
    lpAddresses: {
      80001: '',
      137: '0x34832d9ac4127a232c1919d840f7aae0fcb7315b', // TOKEN-USDC LP
      31337: '0x34832d9ac4127a232c1919d840f7aae0fcb7315b',
    },
    tokenSymbol: 'ICE',
    tokenAddresses: {
      80001: '',
      137: '0x4a81f8796e0c6ad4877a51c86693b0de8093f2ef',
      31337: '0x4a81f8796e0c6ad4877a51c86693b0de8093f2ef',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
    otherExchange: 'DFYN',
  },
]

export default farms
