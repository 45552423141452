import addresses from 'config/constants/contracts'

const chainId = process.env.REACT_APP_CHAIN_ID

export const getCakeAddress = () => {
  return addresses.cake[chainId]
}
export const getMasterChefAddress = () => {
  return addresses.masterChef[chainId]
}
export const getVaultChefAddress = () => {
  return addresses.vaultChef[chainId];
}
export const getVaultTacoStrategyAddress = () => {
  return addresses.vaultTacoStrategy[chainId];
}
export const getSousMasterChefAddress = () => {
  return addresses.sousMasterChef[chainId]
}
export const getMulticallAddress = () => {
  return addresses.mulltiCall[chainId]
}
export const getWbnbAddress = () => {
  return addresses.weth[chainId]
}
export const getLotteryAddress = () => {
  return addresses.lottery[chainId]
}
export const getLotteryTicketAddress = () => {
  return addresses.lotteryNFT[chainId]
}
export const getUsdcAddress = () => {
  return addresses.usdc[chainId]
}
export const getPreSalsaTokenAddress = () => {
  return addresses.preSalsaToken[chainId]
}
export const getPreSalsaSwapAddress = () => {
  return addresses.preSalsaSwap[chainId]
}
export const getTacoBurnerAddress = () => {
  return addresses.tacoBurner[chainId]
}