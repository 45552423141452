export default {
  cake: {
    137: '0x4EB033a22ee1D173EFcd808d5ea6152792814ecd',
    31337: '0x4EB033a22ee1D173EFcd808d5ea6152792814ecd',
  },
  sousMasterChef: {
    137: '0x563373d51Eb8e9F9074216763a39F3aD79597edd',  // Redploy ours
    31337: '0x563373d51Eb8e9F9074216763a39F3aD79597edd',  // Redploy ours
  },
  vaultChef: {
    137: '0x5AEE8E2Ba870bC6206172cb87002b4Db046d74A1',
    31337: '0x5AEE8E2Ba870bC6206172cb87002b4Db046d74A1',
  },
  vaultTacoStrategy: {  // dividends
    137: '0xf77537487E5cbc98531435FD77452bffE62f3Fe0',
    31337: '0xf77537487E5cbc98531435FD77452bffE62f3Fe0',
  },
    // 31337 - Hardhat network backed by Polygon mainnet
    taco: {
      137: '0x4EB033a22ee1D173EFcd808d5ea6152792814ecd',
      31337: '0x4EB033a22ee1D173EFcd808d5ea6152792814ecd',
      80001: '0x2c5ac56d4d4C7D11d54FB584d16168696b19afeD',
    },
    masterChef: {
      137: '0x3526E087F86c9B2f65b3B1dB6769D2E414d7B307',
      31337: '0x3526E087F86c9B2f65b3B1dB6769D2E414d7B307',
      80001: '0x1d89e91b284D97E4A6CDC76C6BE8589055E887b6',
    },
    weth: {
      137: "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
      31337: "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
    },
    matic: {
      137: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
      31337: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
      80001: '0x9c3c9283d3e44854697cd22d3faa240cfb032889',
    },
    wmatic: {
      137: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
      31337: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
      80001: '0x9c3c9283d3e44854697cd22d3faa240cfb032889',
    },
    lottery: {
      137: '',
      31337: '',
      80001: '',
    },
    lotteryNFT: {
      137: '',
      31337: '',
      80001: '',
    },
    mulltiCall: {
      137: '0x631f238d8Ab07C7F05d0CAd581BD0EdCBFE33394',
      31337: '0x631f238d8Ab07C7F05d0CAd581BD0EdCBFE33394',
      80001: '0x08411ADd0b5AA8ee47563b146743C13b3556c9Cc',
    },
    usdc: {
      137: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
      31337: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
      80001: '0x2058a9d7613eee744279e3856ef0eada5fcbaa7e',
    },
    taco_usdc_lp: {
      137: '0x28583858a6018adfaac1e3864937c92ebfb7ac83',
      31337: '0x28583858a6018adfaac1e3864937c92ebfb7ac83',
      80001: '0x2058a9d7613eee744279e3856ef0eada5fcbaa7e',
    },
    pbnb: {
      137: '0x7e9928aFe96FefB820b85B4CE6597B8F660Fe4F4',
      31337: '0x7e9928aFe96FefB820b85B4CE6597B8F660Fe4F4',
      80001: '0x001B3B4d0F3714Ca98ba10F6042DaEbF0B1B7b6F', // not right
    },
    taco_matic_lp: {
      137: '0x347fee1a891a678ee2cce532aba5f416d2fddba0',
      31337: '0x347fee1a891a678ee2cce532aba5f416d2fddba0',
      80001: '0x2058a9d7613eee744279e3856ef0eada5fcbaa7e', // not right
    },
    usdt: {
      137: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
      31337: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
      80001: '0xBD21A10F619BE90d6066c941b04e340841F1F989',
    },
    wbtc: {
      137: '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6', // wbtc
      31337: '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6', // wbtc
      80001: '',
    },
    preSalsaToken: {
      // 31337: '0xCEc5D21D69D03Dd5242987b96453ba3216891ef3',
      31337: '0xEa3B6dA2031DF019EE48EE982E284dd4dCBBCc31', 
      137: '0xEa3B6dA2031DF019EE48EE982E284dd4dCBBCc31', 
    },
    preSalsaSwap: {
      // 31337: '0x25614190d0f6F3A7c6b2e0F624Fd7C016130924B',
      31337: '0x3130F1Ff7dfEd5130efdBC320496D46E987aC4c1',  // used test presalsaswap
      137: '0x3130F1Ff7dfEd5130efdBC320496D46E987aC4c1', 
    },
    tacoBurner: {
      31337: '0x62dAB53a5fc340801C8A5c7D93e5dbaDfdc95Ce5',
      137: '0x62dAB53a5fc340801C8A5c7D93e5dbaDfdc95Ce5',
    },
}
