import { MenuEntry } from 'platinumfinancev2'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Parallel farming',
    icon: 'HamburgerIcon',
    items: [
      {
        label: 'Taco Party Salsa',
        href: 'https://salsa.tacoparty.finance/',
      },
    ],
  },
  // {
  //  label: 'Taco 2 Salsa Swap',
  //  icon: 'HamburgerIcon',
  //  href: '/presale'
  // },
  {
    label: 'Exchange',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://quickswap.exchange/#/swap?outputCurrency=0x4EB033a22ee1D173EFcd808d5ea6152792814ecd',
      },
      {
        label: 'Liquidity',
        href: 'https://quickswap.exchange/#/add/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/0x4EB033a22ee1D173EFcd808d5ea6152792814ecd',
      },
    ],
  },
  {
    label: 'Farms (CLOSED)',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Pools (CLOSED)',
    icon: 'PoolIcon',
    href: '/pools',
  },
  {
    label: 'Vaults (CLOSED)',
    icon: 'VaultIcon',
    href: '/vaults',
  },
  // {
  //   label: 'Community Pools',
  //   icon: 'GroupsIcon',
  //   href: '/community',
  // },
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: '/lottery',
  // },
  {
     label: 'NFT',
     icon: 'NftIcon',
     href: '/nft',
  },
  {
    label: 'Charts',
    icon: 'InfoIcon',
    items: [
      {
        label: 'QuickChart',
        href: 'https://quickchart.app/token/0x4EB033a22ee1D173EFcd808d5ea6152792814ecd',
      },
      {
        label: 'DexGuru',
        href: 'https://dex.guru/token/0x4EB033a22ee1D173EFcd808d5ea6152792814ecd-polygon',
      },
      {
        label: 'PooCoin',
        href: 'https://polygon.poocoin.app/tokens/0x4EB033a22ee1D173EFcd808d5ea6152792814ecd',
      },
    ],
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
         label: 'Github',
         href: 'https://github.com/cheftaco',
      },
      {
        label: 'Docs',
        href: 'https://tacosupreme.gitbook.io/taco-party/',
      },
      {
        label: 'Vfat.tools',
        href: 'https://vfat.tools/polygon/tacoparty/',
      },
      {
        label: 'PolyPup UI',
        href: 'https://ui.polypup.finance/address/0x3526e087f86c9b2f65b3b1db6769d2e414d7b307',
      },
    ],
  },
]

export default config
